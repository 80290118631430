<template>
  <div class="buy-social-230613">
    <div class="header">
      <div class="container d-flex justify-content-between">
        <div class="left">
          <div class="logo">
            <a href="/landings/buy-social-230613" rel="noreferrer noopener" title="바이소셜 홈페이지 바로가기">
              <img src="/assets/img/landings.buy.social.230613.logo.svg" class="img" alt="바이소셜 로고 이미지">
            </a>
          </div>
          <div class="text color-white">
            <b>With local With Social change</b>
            <span>일상의 지출로 사회변화의 주인공이 되어요</span>
          </div>
        </div>
        <div class="right">
          <img src="/assets/img/landings.buy.social.230613.text.ball.png" class="img" alt="사회적가치 혹은 그 분류 안에 드는 단어들의 집합을 구 형태로 조합한 이미지">
        </div>
      </div>
    </div>
    <div class="body">
      <div class="container">
        <div class="content">
          <div class="row gift align-items-lg-center">
            <div class="left col-lg-3">
              <a href="https://blog.naver.com/PostList.naver?blogId=buysocialkorea&from=postList&categoryNo=8&parentCategoryNo=8" target="_blank" class="card">
                <div class="title">
                  <b>기분 좋은 </b>
                  <span>선물</span>
                </div>
                <span class="img"></span>
              </a>
            </div>
            <div class="right col-lg-9 d-flex flex-column list">
              <div v-for="(l, idx) in state.list" :key="idx">
                <a :href="l.link" target="_blank" class="ellipsis">{{ l.title }}</a>
                <span class="text-secondary text-nowrap">{{ getDateFormat(l.pubDate) }}</span>
              </div>
            </div>
          </div>
          <div class="row spot align-items-lg-center">
            <div class="left col-lg-3 justify-content-center">
              <a href="https://blog.naver.com/PostList.naver?blogId=buysocialkorea&from=postList&categoryNo=10" target="_blank" rel="noreferrer noopener" class="card">
                <div class="title">
                  <b>HOT </b>
                  <span>SPOT</span>
                </div>
                <span class="img"></span>
              </a>
            </div>
            <div class="right col-lg-9">
              <a href="https://blog.naver.com/PostList.naver?blogId=buysocialkorea&from=postList&categoryNo=10" target="_blank" rel="noreferrer noopener" class="ellipsis">사회적경제 온오프라인 쇼핑몰을 소개합니다.</a>
            </div>
          </div>
        </div>
        <div class="actions d-flex align-items-center justify-content-between">
          <a :href="l.link" rel="noopener noreferrer" target="_blank" class="btn btn-default" v-for="(l, idx) in links" :key="idx">
            <span>{{ l.title }}</span>
            <span class="arrow"></span>
          </a>
        </div>
        <div class="more">
          <a href="https://blog.naver.com/buysocialkorea" rel="noopener noreferrer" target="_blank" class="btn">블로그 더 보러 가기</a>
        </div>
      </div>
    </div>
    <div class="footer">
      <div class="container">
        <img src="/assets/img/landings.buy.social.230613.footer.logo.svg" alt="KNSE 사회적경제 활성화 전국네트워크 로고" class="img">
        <div class="info">
          <span>04031 서울 마포구 잔다리로 68</span>
          <span>COPYRIGHT© 사회적경제활성화전국네트워크. ALL RIGHTS RESERVED.</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>import {defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import http from "@/scripts/http";

function Component(initialize) {
  this.name = "pagesLandingBuySocial230613";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  setup() {
    const component = new Component(() => {
      const $meta = document.head.querySelector("meta[name=theme-color]");
      $meta && $meta.setAttribute && $meta.setAttribute("content", "#662cc3");

      http.get("/api/landing/buy-social-230613").then(({data}) => {
        state.list = data;
      });
    });

    const state = reactive({
      list: []
    });

    const links = [{
      link: "https://blog.naver.com/PostList.naver?blogId=buysocialkorea&from=postList&categoryNo=1&parentCategoryNo=1",
      title: "바이소셜 캠페인 소개",
    }, {
      link: "https://blog.naver.com/PostList.naver?blogId=buysocialkorea&from=postList&categoryNo=9",
      title: "사회적경제 기업가 스토리",
    }, {
      link: "https://blog.naver.com/PostList.naver?blogId=buysocialkorea&from=postList&categoryNo=6",
      title: "사회적경제 소개",
    }, {
      link: "https://blog.naver.com/PostList.naver?blogId=buysocialkorea&from=postList&categoryNo=7",
      title: "WITH LOCAL",
    },];

    const getDateFormat = (date) => {
      const initialDate = new Date(date);

      const getZeroPrefixed = (value) => {
        return value?.toString().length < 2 ? "0" + value : value;
      };

      return `${initialDate.getFullYear()}-${getZeroPrefixed(initialDate.getMonth() + 1)}-${getZeroPrefixed(initialDate.getDate())}`;
    };

    return {component, state, links, getDateFormat};
  }
});
</script>

<style lang="scss" scoped>
.buy-social-230613 {
  font-family: 'GmarketSans', "Pretendard", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji";

  .header {
    background-color: #662cc3;
    padding: $px49 0;

    .container {
      position: relative;

      .left {
        .logo {
          .img {
            width: $px157;
          }
        }

        .text {
          margin-top: $px49;

          b {
            font-size: $px37;
            display: block;
            word-break: break-word;
          }

          span {
            display: inline-block;
            font-size: $px25;
            font-weight: 300;
            margin-top: $px10;
          }
        }
      }

      .right {
        .img {
          width: $px400;
        }
      }
    }
  }

  .body {
    padding-top: $px70;

    .container {
      .content {
        .row {
          .left {
            .card {
              display: block;
              border-radius: $px16;
              border: 0;
              background: #e9ddfc;
              height: $px130;
              overflow: hidden;
              position: relative;
              padding: $px15;
              text-decoration: none;

              .title {
                font-size: $px18;

                span, b {
                  vertical-align: sub;
                }
              }

              .img {
                background-size: 100% auto;
                background-repeat: no-repeat;
                background-position: center bottom;
                display: inline-block;
                position: absolute;
              }
            }
          }

          &.gift {
            .left {
              .card {
                .img {
                  background-image: url("/assets/img/landings.buy.social.230613.card1.svg");
                  width: $px140;
                  height: $px180;
                  bottom: -70%;
                  right: $px-10;
                }
              }
            }

            .list {
              padding-top: $px10;
              gap: $px5 0;
              font-size: $px18;

              > div {
                align-items: center;
                display: flex;
                justify-content: space-between;

                > a {
                  padding-right: $px5;
                  display: block;
                }

                > span {
                  font-size: $px14;
                }
              }
            }
          }

          &.spot {
            margin-top: $px49;

            .left {
              .card {
                height: $px65;

                .img {
                  background-image: url("/assets/img/landings.buy.social.230613.card2.svg");
                  width: $px100;
                  height: $px70;
                  bottom: 0;
                  right: 0;
                }
              }
            }

            .right > a {
              font-size: $px18;
            }
          }
        }
      }

      .actions {
        padding-top: $px70;
        gap: 0 $px30;

        .btn {
          border-color: $colorSecondary;
          border-radius: $px100;
          flex-grow: 1;
          padding: $px16;

          span {
            display: inline-block;
            vertical-align: sub;
          }

          .arrow {
            display: inline-block;
            width: $px24;
            background-color: $colorSecondary;
            height: $px1;
            margin-left: $px10;
            position: relative;
            vertical-align: unset;

            &:after {
              content: " ";
              display: inline-block;
              position: absolute;
              bottom: $px2;
              right: 0;
              width: $px9;
              height: $px1;
              background: $colorSecondary;
              transform: rotate(-155deg);
            }
          }
        }
      }

      .more {
        padding-top: $px70;
        text-align: center;

        .btn {
          background: #662cc3;
          color: #fff;
          padding: $px15 $px30;

          &:hover {
            background: #885ad0;
          }
        }
      }
    }
  }

  .footer {
    border-top: $px1 solid $colorBorder;
    margin-top: $px70;
    padding-top: $px70;
    padding-bottom: $px70;

    .container {
      .img {
        width: $px150;
      }

      .info {
        padding-top: $px30;

        span {
          display: block;
          font-size: $px14;
        }
      }
    }
  }

  @media (max-width: 991px) {
    .header {
      padding-left: $px15;
      padding-right: $px15;

      .container {
        .left {
          .logo .img {
            width: $px150;
          }

          .text {
            margin-top: $px35;

            b {
              font-size: $px34;
            }

            span {
              font-size: $px22;
            }
          }
        }

        .right .img {
          position: absolute;
          top: $px-30;
          right: 0;
          width: $px135;
        }
      }
    }

    .body {
      padding-top: $px49;
      padding-left: $px15;
      padding-right: $px15;

      .container {
        padding: 0 $px15;

        .content {
          .row {
            .right {
              font-size: $px14;
              max-height: none;
              overflow: initial;
            }

            &.gift, &.spot {
              .left {
                .card {
                  height: auto;
                  margin-bottom: $px15;

                  .title {
                    font-size: $px16;
                  }
                }
              }
            }

            &.gift {
              .left .card {
                .img {
                  top: $px5;
                }
              }
            }

            &.gift {
              .list {
                padding-top: $px5;

                > div {
                  padding: 0 $px15;

                  > a {
                    width: 100%;
                  }

                  > span {
                    font-size: $px12;
                  }
                }
              }
            }

            &.spot {
              margin-top: $px40;

              .right {
                padding-top: $px5;

                > a {
                  padding: 0 $px15;
                  letter-spacing: $px-1;
                }
              }
            }
          }
        }

        .actions {
          padding-top: $px49;
          gap: $px10 0;
          flex-wrap: wrap;

          .btn {
            width: 100%;
          }
        }

        .more {
          padding-top: $px40;
        }
      }
    }

    .footer {
      padding: $px40 $px15 $px30 $px15;
      margin-top: $px40;
    }
  }

  @media (max-width: 767px) {
    .header {
      .container {
        .left {
          .text {
            b {
              font-size: $px26;
            }

            span {
              font-size: $px16;
            }
          }
        }
      }
    }

    .body .container {
      .content {
        .row {
          &.gift, &.spot {
            .right a {
              font-size: $px14;
            }
          }
        }
      }

      .actions {
        .btn {
          font-size: $px14;
        }
      }
    }
  }
}
</style>